<template>
    <div class="row">
      <div class="col-12">
        <card title="Tạo hoa hồng">
            <div class="col-md-5 pr-md-1">
                <base-input label="Id" v-model="searchInput.nickName" name="item-username">
                </base-input>
                 <base-select :options="optionsCom" label="Loại hoa hồng" v-model="searchInput.type" name="item-areaName">
              </base-select>
              </div>
         
              <div class="col-md-5 pr-md-1">
                <base-input label="Số lượng" v-model="searchInput.amount" name="item-username">
                </base-input>
              </div>
              <div class="col-md-5 pr-md-1">
                <base-input type="datetime-local" label="Thời gian" v-model="searchInput.date" name="item-fromTime" >
                </base-input>
              </div>
      
            <base-button @click=createCommission()>Tạo</base-button>
        </card>
      </div>
  
  
  
    </div>
  </template>
  <script>
  import { BaseTable } from "@/components";
  import AuthenticationService from './services/AuthenticationService';
  const tableColumns = ["AreaName", "NickName", "From", "Amount", "Time", "Status"];
  import { VueSelect, VueSelectItem } from 'vue-select'
  import BaseSelect from '../components/BaseSelect.vue';
  
  export default {
    components: {
      
      'vs-select': VueSelect,
    'vs-select-item': VueSelectItem,
    'base-select': BaseSelect,
    },
    props: {
  
    },
    data() {
      return {
        optionsCom: [
        { value: "TRADE", label: "TRADE" },
        { value: "VIP", label: "VIP" },
      ],
        searchInput: {

        nickName: '',
        amount: 0,
        type: '',
        date: '',
      },
      };
    },

    methods: {
        createCommission() {
            let obj = {
                nickName: this.searchInput.nickName,
                amount: this.searchInput.amount,
                type: this.searchInput.type,
                date: this.searchInput.date
            }
            AuthenticationService.createCommission(obj).then((res) => {
                if (res.data.success) {
                    return this.$vs.notification({
                        text: 'Đã tạo thông tin thành công ',
                        color: 'success',
                        iconPack: 'feather',
                    });
                } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
                    this.$router.push('/hadaleon').catch(() => { })
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("INFO");
                    return this.$vs.notification({
                    text: 'Phiên đăng nhập đã hết hạn! ',
                    color: '#4B0082',
                    });
                } else {
                    return this.$vs.notification({
                    text: "Biệt danh không tồn tại",
                    color: 'danger',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle'
                    });
                }
            });
        }
    }
  };
  </script>
  <style>
  
  </style>
  