<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <card>
    <div class="col-md-5 pr-md-1">
      <base-input label="Username ADMIN" v-model="searchInput.username" name="item-username">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsArea" label="Chọn sàn" v-model="searchInput.areaName" name="item-areaName">
      </base-select>
    </div>
    <base-button @click=getInfoAdmin()>Tìm kiếm thông tin</base-button>
    <div class="col-md-5 pr-md-1">
      <base-input label="password" v-model="dataAdmin.password" name="item-password">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Quyền thao tác User" v-model="dataAdmin.feature_profile"
        name="item-feature_profile">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Quyền thao tác xác minh" v-model="dataAdmin.feature_verify" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Quyền thao tác bẻ cầu" v-model="dataAdmin.feature_editBet" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Quyền xem lịch sử nạp tiền" v-model="dataAdmin.feature_deposit" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Quyền thao rút tiền" v-model="dataAdmin.feature_withdraw" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Quyền thao rút kiểm tra KLGD và hoa hồng" v-model="dataAdmin.feature_checkcom"
        name="item-areaName">
      </base-select>
    </div>
    <base-button @click=CreateAdmin()>Tạo tài khoản</base-button>


    <base-button @click=setInfoAdmin()>Thay đổi và Lưu</base-button>

      
  </card>
  <card>
    <div>
      <div class="col-md-5 pr-md-1">
      <base-input label="Address ADMIN" v-model="searchInputWallet.addressWallet" name="item-username">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-input label="Private Key ADMIN" v-model="searchInputWallet.addressPrivateKey" name="item-username">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsArea" label="Chọn sàn" v-model="searchInputWallet.areaName" name="item-areaName">
      </base-select>
    </div>
    <base-button @click=getInfoAdminWallet()>Tìm kiếm thông tin</base-button>
    <base-button @click="active=!active">Sửa thông tin</base-button>
    <vs-dialog v-model="active">
        


      

        <template #footer>
          <div class="footer-dialog">
            <vs-button @click=updateInfoAdminWallet() block>
              Lưu thông tin
            </vs-button>

            <div class="new yellow">
              Lưu ý: kiểm tra kỹ lại địa chỉ ví và key để đảm bảo hệ thống hoạt động ổn định
            </div>
          </div>
        </template>
      </vs-dialog>
    
    </div>
  </card>
  <card>
    <div class="col-md-5 pr-md-1">
          <base-input label="Id" v-model="searchInput.nickName" name="item-username">
          </base-input>
           <base-select :options="optionsArea" label="Chọn sàn" v-model="searchInput.areaName" name="item-areaName">
        </base-select>
        </div>
    <div class="col-md-5 pr-md-1">
        <base-input label="Type" v-model="searchInput.type" name="item-username">
        </base-input>
      </div>
        <div class="col-md-5 pr-md-1">
          <base-input label="Title" v-model="searchInput.title" name="item-username">
          </base-input>
        </div>
          <div class="col-md-5 pr-md-1">
          <base-input label="Content" v-model="searchInput.content" name="item-username">
          </base-input>
        </div>

      <base-button @click=CreateNotify()>Create</base-button>

  </card>
  <card>
    <div class="chat-container">
    <div class="messages">
      <div v-for="(msg, index) in messages" :key="index" class="message">
        <strong>{{ msg.username }}:</strong> {{ msg.message }} <span>{{ new Date(msg.timestamp).toLocaleTimeString() }}</span>
      </div>
    </div>
    <form @submit.prevent="sendMessage">
      <input v-model="username" placeholder="Tên người dùng" required />
      <input v-model="message" placeholder="Tin nhắn" required />
      <button type="submit">Gửi</button>
    </form>
  </div>
  </card>
  </div>
  
</template>
<script>
import AuthenticationService from '../services/AuthenticationService';
import { VueSelect, VueSelectItem } from 'vue-select'
import BaseDropdown from '../../components/BaseDropdown.vue';
import BaseSelect from '../../components/BaseSelect.vue';


export default {
  props: {
    data: {
      type: Object,
      default: () => { }

    }
  },
  components: {
    'vs-select': VueSelect,
    'vs-select-item': VueSelectItem,
    'base-select': BaseSelect,



  },
  data() {
    return {
      active: false,
        active2: false,
        active3: false,
        input1: '',
      showDialog: false,
      optionsArea: [
        { value: "DBZ", label: "DBZ" },
       
      ],
      optionsPer: [
        { value: false, label: "OFF" },
        { value: true, label: "ON" },
      ],
      dataAdmin: {
        username: "",
        areaName: "",
        password: "",
        feature_profile: 0,
        feature_verify: 0,
        feature_editBet: 0,
        feature_deposit: 0,
        feature_withdraw: 0,
        feature_checkcom: 0,
      },
      searchInput: {
        areaName: 'DBZ',
        username: '',
        nickName: '',
        id: 0,
        type: '',
        title: '',
        content: ''
      },
      searchInputWallet: {
        areaName: 'DBZ',
        addressWallet: '',
        addressPrivateKey: '',
      }



    }
  },
  methods: {
    confirmDelete() {
      // Xử lý logic xóa ở đây
      console.log("Đã xác nhận xóa");
      this.showDialog = false;
    },
    cancelDelete() {
      console.log("Đã hủy xóa");
      this.showDialog = false;
    },
    CreateAdmin() {
      let obj = {
        username: this.searchInput.username,
        areaName: this.searchInput.areaName,
        password: this.dataAdmin.password,
      }
      AuthenticationService.createAdmin(obj)
        .then((res) => {
          if (res.data.success) {
            return this.$vs.notification({
              text: 'Đã tạo thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == '') {
            return this.$vs.notification({
              text: "Không thể tạo tài khoản !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },

    getInfoAdmin() {
      let obj = {
        username: this.searchInput.username,
        areaName: this.searchInput.areaName,
      }
      AuthenticationService.getAdminInfo(obj)
        .then((res) => {
          if (res.data.success) {
            this.dataAdmin = res.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == 'UserNotFound') {
            return this.$vs.notification({
              text: "Không thể tìm kiếm thông tin !",
              color: 'danger',
              iconPack: 'feather',

            });
          }
        })
    },

    getInfoAdminWallet() {
      let obj = {
        areaName: this.searchInputWallet.areaName
      };
      AuthenticationService.getInfoAdminWallet(obj).then((res) => {
        if (res.data.success) {
          this.dataWallet = res.data.data;
          console.log(this.dataWallet);
          
          this.searchInputWallet.addressWallet = this.dataWallet.address; 
          this.searchInputWallet.addressPrivateKey = this.dataWallet.privateKey; 
        }
        return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
      });

    },
    updateInfoAdminWallet() {
      let obj = {
        areaName: this.searchInputWallet.areaName,
        address: this.searchInputWallet.addressWallet,
        privateKey: this.searchInputWallet.addressPrivateKey
      };
      AuthenticationService.updateInfoAdminWallet(obj).then((res) => {
        if (res.data.success) {
          
          return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            }); 
        }
       
      });

    },
    setInfoAdmin() {
      let obj = {
        username: this.searchInput.username,
        areaName: this.searchInput.areaName,
        password: this.dataAdmin.password,
        feature_profile: this.dataAdmin.feature_profile,
        feature_verify: this.dataAdmin.feature_verify,
        feature_editBet: this.dataAdmin.feature_editBet,
        feature_deposit: this.dataAdmin.feature_deposit,
        feature_withdraw: this.dataAdmin.feature_withdraw,
        feature_checkcom: this.dataAdmin.feature_checkcom
      }
      AuthenticationService.setInfoAdmin(obj)
        .then((res) => {
          if (res.data.success) {
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/hadaleon').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == '') {
            return this.$vs.notification({
              text: "Không thể đổi thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },
    CreateNotify() {
      let obj = {
        nickName: this.searchInput.nickName,
        areaName: this.searchInput.areaName,
        type: this.searchInput.type,
        title: this.searchInput.title,
        content: this.searchInput.content
      }
      AuthenticationService.CreateNotify(obj)
        .then((res) => {
          if (res.data.success) {
            return this.$vs.notification({
              text: 'Đã tạo thông báo thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          }
      })
    }

  },
  computed: {

  },
}
</script>

<style>
.vs-button__content {
  background-color: #27293d;
}
.vs-dialog {
  background-color: #1e1e28;
}
.yellow {
  color: #d1c300
}</style>
